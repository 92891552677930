import React from 'react';
import './App.css';
import { ArrowRight, Moon, Shield, Zap, Clock, ArrowUpRight, Contact } from 'lucide-react';
import FeaturesSection from './components/FeaturesSection';
import homeImage from './assets/home.png';
import gudaImage from './assets/GUDA.png';
import GudaComparison from './components/GudaComparision';
import Header from './components/Header';
import Roadmap from './components/Roadmap';
import ContactSection from './components/ContactSection';
import { Analytics } from '@vercel/analytics/react';

const LandingPage = () => {

  const roadmapSteps = [
    { quarter: "Q1 2024", milestone: "Platform Launch" },
    { quarter: "Q2 2024", milestone: "Mobile App Release" },
    { quarter: "Q3 2024", milestone: "International Expansion" },
    { quarter: "Q4 2024", milestone: "New Payment Methods" }
  ];

  return (
    <div className="root">
      <Header />

      {/* Hero Section */}
      <section id="home" className="pt-32 pb-20 px-4">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-3/5 mb-10 md:mb-0">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 text-white">
              Instant Crypto to Fiat
            </h1>
            <p className="text-xl text-white mb-8">
              Complete OffRamp and OnRamp transactions in just 60-90 seconds.
            </p>
            <button className="bg-[#C8F560] hover:bg-blue-700 text-black px-8 py-3 rounded-lg flex items-center">
              Coming Soon
            </button>
          </div>
          <div className="md:w-2/5 flex justify-center">
            <div className="relative w-full h-64">
              <div className="absolute inset-0 bg-blue-500/20 rounded-full blur-3xl"></div>
              <img
                src={homeImage}
                alt="Crypto illustration"
                className="relative z-10 w-full h-full object-contain"
              />
            </div>
          </div>
        </div>
      </section>

      <FeaturesSection />
      <GudaComparison />
      <Analytics/>

      {/* Roadmap Section */}
      <Roadmap />

      <ContactSection />

      {/* Footer Section */}
      <footer className="bg-black text-white p-6 mt-10">
        <hr className="border-white mb-4" />
        <div className="flex justify-center">
          <img src={gudaImage} alt="GUDA" className="w-32 h-auto" />
        </div>
        <p className="text-center mt-4">© 2024 GUDA. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
